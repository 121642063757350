<template>
  <div>
    <b-table
      :fields="fields"
      :items="items"
      class="text-center"
      show-empty
      empty-text="Não há clietes monitorados"
      :current-page="page"
      :per-page="10"
      responsive="xl"
      hover
      @row-clicked="(data) => $router.push(`/monitoramento-transacoes/detalhes/${data.establishment_id}`)"
    >
      <template #cell(message)="data">
        {{ data.item.merchant_monitored_detail ? data.item.merchant_monitored_detail.message : '---' }}
      </template>
    </b-table>

    <b-pagination
      v-model="page"
      :total-rows="items.length"
      :per-page="10"
      align="center"
      size="sm"
      class="my-0"
    />
  </div>
</template>

<script>
import { BTable, BPagination } from 'bootstrap-vue';
import AlertService from '@/service/transaction_monitoring/alerts';

export default {

    components: {
        BTable, BPagination,
    },
    data() {
        return {
            items: [],
            page: 1,
            fields: [
                { key: 'name', label: 'nome' },
                { key: 'establishment_id', label: 'ec' },
                { key: 'legal_tax_name', label: 'Razão Social' },
                { key: 'alerts', label: 'Nº Alertas' },
                { key: 'message', label: 'Mensagem' },
            ],
        };
    },

    async mounted() {
        const { data: { data: customers } } = await AlertService.getMonitored();
        this.items = customers;
    },
};
</script>
