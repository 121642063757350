<template>
  <div>
    <b-table
      v-if="!isLoading"
      :items="items"
      responsive="xl"
      :current-page="page"
      :per-page="10"
      class="text-center"
      show-empty
      empty-text="Não há alertas"
      hover
      :fields="fields"
      @row-clicked="redirectTo"
    >
      <template #cell(name)="data">
        {{ data.item.merchant_name }} {{ fields }}
      </template>

      <template #cell(legalName)="data">
        {{ data.item.legal_tax_name }}
      </template>

      <template #cell(establishment_id)="data">
        <span class="text-primary font-weight-bold">
          {{ data.item.establishment_id }}
        </span>
      </template>

      <template #cell(max_ticket)="data">
        <alert-row
          :param="'Ticket Máximo'"
          :value="data.item.max_ticket"
        />
      </template>

      <template #cell(international_card)="data">
        <alert-row
          :param="'Cartão Internacional'"
          :value="data.item.international_card"
        />
      </template>

      <template #cell(typed)="data">
        <alert-row
          :param="'Digitada'"
          :value="data.item.typed"
        />
      </template>

      <template #cell(mean_ticket)="data">
        <alert-row
          :param="'Ticket Médio'"
          :value="data.item.mean_ticket"
        />
      </template>

      <template #cell(fallback)="data">
        <alert-row
          :param="'Fallback'"
          :value="data.item.fallback"
        />
      </template>

      <template #cell(same_card)="data">
        <alert-row
          :param="'Mesmo Cartão'"
          :value="data.item.same_card"
        />
      </template>

      <template #cell(stripe)="data">
        <alert-row
          :param="'Stripe'"
          :value="data.item.stripe"
        />
      </template>

      <template #cell(insert_mode)="data">
        <alert-row
          :param="'Insert Mode'"
          :value="data.item.insert_mode"
        />
      </template>

      <template #cell(total_sales)="data">
        <alert-row
          :param="'Venda Total'"
          :value="data.item.total_sales"
        />
      </template>

      <template #cell(not_approved)="data">
        <alert-row
          :param="'Não Aprovada'"
          :value="data.item.not_approved"
        />
      </template>

      <template #cell(outside_hour)="data">
        <alert-row
          :param="'Fora de Hora'"
          :value="data.item.outside_hour"
        />
      </template>

      <template #cell(ecommerce_fail)="data">
        <alert-row
          :param="'Não Aprovado Ecommerce'"
          :value="data.item.ecommerce_fail"
        />
      </template>
    </b-table>

    <b-pagination
      v-model="page"
      :total-rows="items.length"
      :per-page="10"
      align="center"
      size="sm"
      class="my-0"
    />
  </div>
</template>

<script>
import { BTable, BPagination } from 'bootstrap-vue';
import AlertRow from './AlertRow.vue';

import AlertService from '@/service/transaction_monitoring/alerts';

const alertTypeIdToTitle = Object.freeze({
    1: 'Ticket Máximo',
    2: 'Cartão Internacional',
    3: 'Digitada',
    4: 'Ticket Médio',
    5: 'Fallback',
    6: 'Mesmo Cartão',
    7: 'Stripe',
    8: 'Insert',
    9: 'Venda Total',
    10: 'Não Aprovada',
    11: 'Fora de Hora',
    12: 'Ecommerce Cancelada',
});

export default {
    components: {
        BTable,
        BPagination,
        AlertRow,
    },

    data() {
        return {
            items: [],
            alertTypeIdToTitle,
            alertTypes: [],
            isLoading: true,
            page: 1,
        };
    },

    computed: {
        fields() {
            const fields = [
                { key: 'establishment_id', label: 'ec' },
                { key: 'merchant_name', label: 'nome' },
                { key: 'legalName', label: 'Razão Social' },
                { key: 'ranking', label: 'Ranking', sortable: true },
            ];

            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            const alertsReversed = this.alertTypes.reverse();

            alertsReversed.forEach(element => {
                fields.push({
                    key: this.formatName(element.name),
                    label: `#${element.id}`,
                    sortable: true,
                });
            });

            return fields;
        },
    },

    async mounted() {
        this.callLoading(true);
        await this.getAlerts();
        this.alertTypes = (await AlertService.getAlertType()).data;
        this.isLoading = false;
        this.callLoading(false);
    },

    methods: {
        formatName(name) {
            return name.toLowerCase().split(' ').join('_');
        },

        async getAlerts() {
            const {
                data: alerts,
            } = await AlertService.getUnverifiedAlerts();

            this.items = alerts.sort((a, b) => b.ranking - a.ranking);
        },

        redirectTo(data) {
            this.$router.push(`/monitoramento-transacoes/detalhes/${data.establishment_id}`);
        },
    },

};

</script>
