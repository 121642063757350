<template>
  <span
    v-b-tooltip.hover.top="param"
    :class="`font-weight-bold ${value && 'text-danger'}`"
  >
    {{ value }}
  </span>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue';

export default {
    directives: {
        'b-tooltip': VBTooltip,
    },

    props: {
        value: {
            type: Number,
            default: 0,
        },

        param: {
            type: String,
            default: '',
        },
    },
};
</script>
