<template>
  <b-container fluid>
    <b-card>
      <alert-table />
    </b-card>

    <b-card>
      <merchant-table />
    </b-card>

  </b-container>
</template>

<script>
import {
    BContainer, BCard,
} from 'bootstrap-vue';
import '@validations';

import AlertTable from './components/AlertTable.vue';
import MerchantTable from './components/MerchantTable.vue';

export default {
    components: {
        BContainer,
        BCard,
        AlertTable,
        MerchantTable,
    },
};
</script>

<style lang="scss">
</style>
